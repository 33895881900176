/**
 * Created by Giannis Kallergis on 2019-08-15
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

/** Utilities */
import { media } from "../utils/media";

const Container = styled.article`
  height: 250px;
  width: 340px;
  margin-right: 32px;
  border-radius: 1rem;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.15);
  background: ${props => props.theme.colors.primary};

  overflow: hidden;
  scroll-snap-align: center;

  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: initial;
  transition-property: transform box-shadow;
  transform-origin-x: center;
  transform-origin-y: center;

  a {
    color: white;
    text-decoration: none;
  }

  :active,
  :hover {
    box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.15);
    transform: scale(1.05, 1.05);
  }

  @media ${media.tablet} {
    height: 200px;
    width: 270px;
  }

  @media ${media.phone} {
    height: 150px;
    width: 220px;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  display: inline-flex;
  flex-direction: column;

  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;

  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: initial;
  transition-property: initial;

  :active,
  :hover {
    height: 110%;
    padding-bottom: 40px;
  }
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  text-align: center;
  margin-bottom: 0.75rem;

  @media ${media.tablet} {
    font-size: 1.5rem;
  }

  @media ${media.phone} {
    font-size: 1rem;
  }
`;

const Description = styled.h4`
  flex: 1;
  display: flex;
  align-items: flex-end;
  color: white;
  font-size: 1.1rem;
  font-weight: 100;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 0;

  @media ${media.tablet} {
    font-size: 0.9rem;
  }

  @media ${media.phone} {
    font-size: 0.7rem;
  }
`;

const FeaturedPreview = props => (
  <Container>
    <Link to={props.url}>
      <InfoContainer style={{ backgroundImage: `url("${props.imgUrl}")` }}>
        <TitleContainer>
          <Title>{props.title}</Title>
        </TitleContainer>
        {!!props.description && <Description>{props.description}</Description>}
      </InfoContainer>
    </Link>
  </Container>
);

FeaturedPreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  imgUrl: PropTypes.string,
};

FeaturedPreview.defaultProps = {
  url: "/",
  imgUrl: "https://source.unsplash.com/random",
};

export default FeaturedPreview;
