const birthday = new Date();
birthday.setFullYear(1991);
birthday.setMonth(1);
birthday.setDate(3);
birthday.setHours(0);
birthday.setMinutes(0);
birthday.setSeconds(0);
birthday.setMilliseconds(0);

export default function getAge() {
  const now = Date.now();
  const diff = now - birthday.getTime();
  const diffDate = new Date(diff);
  return Math.abs(diffDate.getFullYear() - 1970)
}
