/**
 * Created by Giannis Kallergis on 2018-12-26
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";

/** Components */
import Subline from "./Subline";
import TagsContainer from "./TagsContainer";
import TLDR from "./TLDR";

/** Utilities */

const Post = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
`;

const Title = styled.h2`
  position: relative;
  margin-bottom: 0.75rem;
  a {
    text-decoration: none;
  }
`;

const Initiale = styled.span`
  position: absolute;
  font-size: 7rem;
  transform: translate(-25%, -50%);
  opacity: 0.08;
  user-select: none;
  z-index: -1;
`;

export default class ArticlePreview extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    tldr: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    timeToRead: PropTypes.string.isRequired,
    category: PropTypes.string,
    date: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    tags: [],
  };

  /** Renderers */

  render() {
    const { title, tldr, slug, date, timeToRead, category } = this.props;
    const firstChar = title.charAt(0);
    const tags = this.props.tags || [];
    return (
      <Post>
        <Title>
          <Initiale>{firstChar}</Initiale>
          <Link to={slug}>{title}</Link>
        </Title>
        <Subline>
          {!!date && <>{date} &mdash; </>}
          {startCase(timeToRead) + " "}
          {!!category && (
            <>
              &mdash; In <Link to={`/categories/${kebabCase(category)}`}>{category}</Link>
            </>
          )}
        </Subline>
        <TLDR tldr={tldr} />
        <TagsContainer tags={tags.map(t => ({ name: t }))} showTitle={false} />
      </Post>
    );
  }
}

export const query = graphql`
  fragment ArticleInfo on MarkdownRemark {
    id
    html
    fields {
      slug
      timeToRead
    }
    frontmatter {
      title
      date(formatString: "DD/MM/YYYY")
      tldr
      tags
      category
    }
  }
`;
