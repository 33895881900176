import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

/** Models / Types */
import { ArticleNode } from "../propTypes";

/** Components */
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Wrapper from "../components/Wrapper";
import { TextButton } from "../components/Button";
import ContactIcon from "../images/contact.svg";
import SectionTitle from "../components/SectionTitle";
import ArticlePreview from "../components/ArticlePreview";
import Hero from "../components/Hero";
import Content from "../components/Content";
import SocialIcon from "../components/SocialIcon";
import FeaturedPreview from "../components/FeaturedPreview";

/** Utilities */
import * as Theme from "../../config/Theme";
import { media } from "../utils/media";
import getAge from "../utils/getAge";

const SocialIcons = styled.div`
  margin-top: 1rem;
`;

const Featured = styled.div`
  overflow-x: scroll;
  overflow-y: visible;
  grid-column: 2;
  display: flex;
  padding-bottom: 60px;
  padding-top: 60px;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // FireFox
  ::-webkit-scrollbar {
    display: none;
  }

  @media ${media.tablet} {
    padding-bottom: 40px;
  }

  @media ${media.phone} {
    padding-bottom: 40px;
  }
`;

const VerticalScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: visible;
  padding: 20px;
  padding-top: 60px;
  margin: auto;
  :before,
  :after {
    content: "";
    width: 1rem;
  }
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // FireFox
  ::-webkit-scrollbar {
    display: none;
  }
`;

export default class IndexPage extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: ArticleNode.isRequired,
          })
        ).isRequired,
      }),
    }).isRequired,
  };

  /** Renderers */

  render() {
    const articles = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <SEO title={"Home"} />
        <Wrapper>
          <Hero>
            <h1>Hi.</h1>
            <p>
              I&apos;m John, a {getAge()} years old Software Engineer from Rethymnon, Crete
              (Greece). <br />I love everything that has to do with software from concept to design
              to development to deployment and maintenance.
            </p>
            <Link to="/contact">
              <TextButton big>
                <ContactIcon />
                Contact
              </TextButton>
            </Link>
            <SocialIcons>
              <SocialIcon type={"twitter"} stroke={Theme.colors.primary} size={25} />
              <SocialIcon type={"linkedIn"} stroke={Theme.colors.primary} size={25} />
              <SocialIcon type={"github"} stroke={Theme.colors.primary} size={25} />
              <SocialIcon type={"bitBucket"} stroke={Theme.colors.primary} size={25} />
            </SocialIcons>
          </Hero>
          <FeaturedSections disable />
          <Content>
            <SectionTitle>Latest Articles</SectionTitle>
            {articles.map(_renderArticlePreview)}
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}

function FeaturedSections({ disable }) {
  if (disable) {
    return null;
  }
  return (
    <>
      <div style={{ gridColumn: 2 }}>
        <SectionTitle marginBottom={"2rem"}>Quick Links</SectionTitle>
      </div>
      <Featured>
        <VerticalScrollContainer>
          <FeaturedPreview
            title="React Native Maps UI Maps"
            description="In this series we're going to dive deep into the react-native-maps package."
            imgUrl="https://source.unsplash.com/random?1"
          />
          <FeaturedPreview //
            title="React Native"
            imgUrl="https://source.unsplash.com/random?2"
          />
          <FeaturedPreview //
            title="Algorithms"
            imgUrl="https://source.unsplash.com/random?3"
          />
          <FeaturedPreview //
            title="Data Structures"
            imgUrl="https://source.unsplash.com/random?4"
          />
          <FeaturedPreview //
            title="Architecture"
            imgUrl="https://source.unsplash.com/random?5"
          />
          <FeaturedPreview //
            title="Object Oriented Programming"
            imgUrl="https://source.unsplash.com/random?6"
          />
          <FeaturedPreview //
            title="Docker"
            imgUrl="https://source.unsplash.com/random?7"
          />
          <FeaturedPreview //
            title="React Native"
            imgUrl="https://source.unsplash.com/random?8"
          />
          <FeaturedPreview //
            title="Machine Learning"
            imgUrl="https://source.unsplash.com/random?9"
          />
          <FeaturedPreview title="Switf UI" imgUrl="https://source.unsplash.com/random?10" />
        </VerticalScrollContainer>
      </Featured>
    </>
  );
}

function _renderArticlePreview(article) {
  return (
    <ArticlePreview
      key={article.node.fields.slug}
      title={article.node.frontmatter.title}
      date={article.node.frontmatter.date}
      tldr={article.node.frontmatter.tldr}
      timeToRead={article.node.fields.timeToRead}
      slug={article.node.fields.slug}
      category={article.node.frontmatter.category}
      tags={article.node.frontmatter.tags}
    />
  );
}

// noinspection JSUnusedGlobalSymbols
export const IndexQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          ...ArticleInfo
        }
      }
    }
  }
`;
