/**
 * Created by Giannis Kallergis on 2018-12-29
 */

import styled from "styled-components";

/** Utilities */
import { media } from "../utils/media";

const Hero = styled.div`
  grid-column: 2;
  padding: 3rem 2rem 3rem 2rem;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.grey.dark};
  h1 {
    color: ${props => props.theme.colors.primary};
  }
  p {
    font-size: 1.3rem;
    margin-top: -1rem;
    @media ${media.tablet} {
      font-size: 1rem;
    }
    @media ${media.phone} {
      font-size: 1rem;
    }
  }
`;

export default Hero;
